<template>
  <MasterLayout :main_name="main_name" :link="link">
    <div class="sidebar_right_content profile_block">
      <div id="Mechanics" class="w3-container w3-border city">
        <router-link to="/villa" class="add_icon" v-show="!form.id">
          <span><i class="fa fa-plus" aria-hidden="true"></i></span>
        </router-link>
        <div v-if="dashboardBooking.length > 0">
          <div
            class="tab_inner_content"
            v-show="!form.id"
            :style="{
              backgroundImage: 'url(' + banner02 + ')',
              'border-radius': '10px',
            }"
          >
            <div
              class="tab_inner_content_main row"
              v-for="card in dashboardBooking"
              :key="card.id"
            >
              <div
                class="d_flex col-sm"
                v-if="card.type == 'EventBooking' && card.event"
              >
                <div
                  class="tab_inner_content_image"
                  v-show="card.event.event_image[0]"
                >
                  <img :src="card.event.event_image[0].image" />
                </div>
                <div class="tab_inner_content_date">
                  <span>{{ card.date_format }}</span>
                  <div v-for="villaData in card.villa" :key="villaData">
                    <p>
                      {{
                        villaData.first_name == "null" ||
                        villaData.first_name == ""
                          ? ""
                          : villaData.first_name
                      }}
                      {{
                        villaData.middle_name == "null" ||
                        villaData.middle_name == ""
                          ? ""
                          : villaData.middle_name
                      }}
                      {{
                        villaData.last_name == "null" ||
                        villaData.last_name == ""
                          ? ""
                          : villaData.last_name
                      }}
                    </p>
                    <p>{{ card.event.name }}</p>
                  </div>
                </div>
              </div>
              <div
                class="d_flex col-sm"
                v-else-if="card.type == 'OneDayPicnic'"
              >
                <div class="tab_inner_content_image">
                  <img
                    src="../../../public/images/inner_page/about_us_01.jpg"
                  />
                </div>
                <div class="tab_inner_content_date">
                  <span>{{ card.date_format }}</span>
                  <div v-for="villaData in card.villa" :key="villaData">
                    <p>
                      {{
                        villaData.first_name == "null" ||
                        villaData.first_name == ""
                          ? ""
                          : villaData.first_name
                      }}
                      {{
                        villaData.middle_name == "null" ||
                        villaData.middle_name == ""
                          ? ""
                          : villaData.middle_name
                      }}
                      {{
                        villaData.last_name == "null" ||
                        villaData.last_name == ""
                          ? ""
                          : villaData.last_name
                      }}
                    </p>
                    <p>One Day Picnic</p>
                  </div>
                </div>
              </div>
              <div class="d_flex col-sm" v-else>
                <div
                  class="tab_inner_content_image"
                  v-show="card.villa[0].villa_type_master"
                >
                  <img
                    :src="
                      card.villa[0].villa_type_master
                        ? card.villa[0].villa_type_master.villa_images[0]
                        : ``
                    "
                  />
                </div>
                <div class="tab_inner_content_date">
                  <span>{{ card.date_format }}</span>
                  <div v-for="villaData in card.villa" :key="villaData">
                    <p>
                      {{
                        villaData.first_name == "null" ||
                        villaData.first_name == ""
                          ? ""
                          : villaData.first_name
                      }}
                      {{
                        villaData.middle_name == "null" ||
                        villaData.middle_name == ""
                          ? ""
                          : villaData.middle_name
                      }}
                      {{
                        villaData.last_name == "null" ||
                        villaData.last_name == ""
                          ? ""
                          : villaData.last_name
                      }}
                    </p>
                    <p>
                      {{
                        villaData.villa_type_master
                          ? villaData.villa_type_master.villa_name
                          : ""
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="footer">
                <span class="amount"
                  ><span class="rupee">₹</span
                  >{{
                    parseFloat(card.final_amount ? card.final_amount : 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}.00</span
                >
                <div
                  class="tab_inner_content_add_user"
                  v-if="card.order && card.order.status === 'COMPLETED'"
                >
                  <div class="aview_bar mr-1 success">
                    <a> Paid </a>
                  </div>
                  <div class="aview_bar">
                    <a
                      :href="
                        'http://chart.apis.google.com/chart?cht=qr&chs=250x250&chld=H|0&chl=' +
                        authUser.id +
                        '-' +
                        authUser.mobile +
                        '-' +
                        authUser.user_type +
                        '&choe=UTF-8'
                      "
                      data-lightbox="models"
                      data-title="STRUCTURE-2"
                    >
                      My QRcode
                    </a>
                  </div>
                </div>
                <div
                  class="tab_inner_content_add_user"
                  v-if="card.order && card.order.status === '2'"
                >
                  <div class="aview_bar mr-1 warning">
                    <a v-if="card.order" :href="card.order.pay_link_web">
                      Pay Now
                    </a>
                  </div>
                </div>
                <div
                  class="tab_inner_content_add_user"
                  v-if="card.order && card.order.status === '3'"
                >
                  <div class="aview_bar mr-1 danger">
                    <a> Cancelled </a>
                  </div>
                </div>
              </div>
              <!-- <div class="footer" v-if="card.payment_mode_id == '1'">
                                <span class="amount">₹{{ parseFloat(card.final_amount).toFixed(2) }}</span>
                            </div> -->
            </div>
          </div>
        </div>
        <div class="v_bk_cat" v-else-if="form.person == 0">
          <img :src="frame" />
          <div class="v_con_main">You Have No Upcoming Booking</div>
          <div class="bk_btn">
            <router-link to="/villa" class="site_bk_btn">
              <span>Book Villa</span>
            </router-link>
          </div>
        </div>
        <ul class="ui-formSlide">
          <!-- v-show="form.id" -->
          <li
            class="primary_mbm_detail_block"
            v-if="form.id && memberReserve == false"
          >
            <div class="pm_detail_block">
              <div data-repeater-list="group-a" style="margin-bottom: 80px">
                <div v-for="(item, index) in form.persons" :key="item">
                  <div
                    :class="
                      index === 0
                        ? `border-bottom sectionBlock mb-1 pt-1 pb-1`
                        : index === 1
                        ? `selectioBoxOther pt-1`
                        : ``
                    "
                    class="d-flex row memeber_list"
                  >
                    <div
                      class="float-left green_font type_title"
                      v-show="index === 0"
                    >
                      <div class="col-12">
                        <span>Primary Member Details</span>
                      </div>
                    </div>

                    <div
                      class="float-left green_font type_title"
                      v-show="index === 1"
                    >
                      <div class="col-12">
                        <span>Other Member Details</span>
                      </div>
                    </div>

                    <input
                      type="hidden"
                      name="detail_id"
                      :id="`detail_id` + index"
                      v-model="form.persons[index].detail_id"
                    />
                    <input
                      type="hidden"
                      name="user_id"
                      :id="`user_id` + index"
                      v-model="form.persons[index].user_id"
                    />
                    <input
                      type="hidden"
                      name="barcode_id"
                      :id="`barcode_id` + index"
                      v-model="form.persons[index].barcode_id"
                    />

                    <div class="pm_detail_form">
                      <div
                        :class="
                          index == 0
                            ? `pm_detail_col`
                            : `pm_detail_col other_member_detail`
                        "
                      >
                        <div class="pm_detail_12">
                          <div class="pm_detail_lt">
                            <div class="pm_deatil_icon">
                              <img :src="user_icon" />
                            </div>
                          </div>
                          <div class="pm_detail_rt">
                            <div class="pm_deatil_in">
                              <label class="pm_label"
                                >First Name<span style="color: red"
                                  >*</span
                                ></label
                              >
                              <input
                                type="text"
                                class="pm_input"
                                name="first_name"
                                v-model="form.persons[index].first_name"
                                @keyup="
                                  form.persons[index].first_name.length > 0
                                    ? [
                                        (firstError[index] = false),
                                        (disabled = false),
                                      ]
                                    : (firstError[index] = true)
                                "
                              />
                              <div
                                class="fw-bold fst-italic error"
                                v-show="firstError[index]"
                              >
                                Please enter First name.
                              </div>
                            </div>
                            <div class="pm_deatil_in">
                              <label class="pm_label">Middle Name</label>
                              <input
                                type="text"
                                class="pm_input"
                                name="middle_name"
                                v-model="form.persons[index].middle_name"
                              />
                            </div>
                            <div class="pm_deatil_in">
                              <label class="pm_label"
                                >Last Name<span style="color: red"
                                  >*</span
                                ></label
                              >
                              <input
                                type="text"
                                class="pm_input"
                                name="last_name"
                                v-model="form.persons[index].last_name"
                                @keyup="
                                  form.persons[index].last_name.length > 0
                                    ? [
                                        (lastError[index] = false),
                                        (this.disabled = false),
                                      ]
                                    : (lastError[index] = true)
                                "
                              />
                              <div
                                class="error fw-bold fst-italic"
                                v-show="lastError[index]"
                              >
                                Please enter Last name.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="pm_detail_12">
                          <div class="pm_detail_lt">
                            <div class="pm_deatil_icon">
                              <img :src="call_icon" />
                            </div>
                          </div>
                          <div class="pm_detail_rt">
                            <div class="pm_deatil_in">
                              <label class="pm_label"
                                >Phone Number<span style="color: red"
                                  >*</span
                                ></label
                              >
                              <input
                                type="number"
                                class="pm_input"
                                name="mobile"
                                v-model="form.persons[index].mobile"
                                id="mobile"
                                @keyup="
                                  form.persons[index].mobile.toString().length >
                                  0
                                    ? [
                                        (mobileError[index] = false),
                                        (this.disabled = false),
                                      ]
                                    : (mobileError[index] = true)
                                "
                              />
                              <div
                                class="error fw-bold fst-italic"
                                v-show="mobileError[index]"
                              >
                                Please enter Mobile.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="pm_detail_12"
                          v-if="form.type != 'EventBooking'"
                        >
                          <div class="pm_deatil_in" style="display: flex">
                            <div>
                              <label
                                :for="`files${index}`"
                                class="main_button_user_square"
                                ><img :src="document" alt="document"
                              /></label>
                              <input
                                type="file"
                                name="id_proof"
                                ref="proofFile"
                                :id="`files${index}`"
                                @change="uploadProofImage(index, $event)"
                                accept="image/png, image/gif, image/jpeg"
                                style="display: none"
                              />
                            </div>
                            <div v-if="form.persons[index].id_tmp_proof">
                              <img
                                :src="form.persons[index].id_tmp_proof"
                                class="rounded"
                                height="50"
                                width="50"
                                style="margin-left: 10px"
                              />
                            </div>
                            <div
                              v-else
                              style="
                                border: 1px solid #42aea3;
                                height: 50px;
                                width: 50px;
                                border-radius: 5px;
                              "
                            ></div>
                            <div class="d-flex trash" v-if="index !== 0">
                              <span
                                @click="
                                  removeContent(
                                    index,
                                    form.persons[index].detail_id
                                  )
                                "
                              >
                                <img :src="trash" alt="delete" />
                              </span>
                            </div>
                            <div class="d-flex trash disbaled" v-else>
                              <span>
                                <img :src="trash" alt="delete" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="pm_detail_col"
                        style="border: none; padding: 0"
                        v-show="form.person > form.persons.length"
                      >
                        <div class="col-12">
                          <div
                            class="pm_detail_rt"
                            style="justify-content: end"
                            v-show="index >= parseInt(form.persons.length - 1)"
                          >
                            <div class="add_member">
                              <button
                                type="button"
                                class="main_button_user_square_plus"
                                @click="addToContent()"
                                data-repeater-create
                              >
                                <span class="plus">+</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="main_reserves_row new_bottom">
              <div class="check_inr_right">
                <ul class="check_rt_ul">
                  <li class="check_rt_li">
                    <div class="check_rt_div">
                      <div class="check_text">
                        <span>Persons</span>
                      </div>
                      <div class="check_numver">
                        <span>
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                              fill="#153936"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                              fill="#153936"
                            />
                          </svg>
                          x {{ form.person ? form.person : 0 }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li class="check_rt_li" v-if="form.total_child">
                    <div class="check_rt_div">
                      <div class="check_text">
                        <span>Childrens</span>
                      </div>
                      <div class="check_numver">
                        <span>
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                              fill="#153936"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                              fill="#153936"
                            />
                          </svg>
                          x {{ form.total_child ? form.total_child : 0 }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li class="check_rt_li">
                    <div class="check_rt_div">
                      <div class="check_text">
                        <span>Price</span>
                      </div>
                      <div class="check_numver">
                        <span
                          ><span class="rupee">₹</span
                          >{{
                            parseFloat(showCalc.total ? showCalc.total : 0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}.00</span
                        >
                      </div>
                    </div>
                  </li>
                  <li class="check_rt_li" v-if="showCalc.extra_person_charge">
                    <div class="check_rt_div">
                      <div class="check_text">
                        <span>Extra Person</span>
                      </div>
                      <div class="check_numver">
                        <span
                          ><span class="rupee">₹</span
                          >{{
                            parseFloat(
                              showCalc.extra_person_charge
                                ? showCalc.extra_person_charge
                                : 0
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}.00</span
                        >
                      </div>
                    </div>
                  </li>
                  <li class="check_rt_li" v-if="showCalc.festival_charge">
                    <div class="check_rt_div">
                      <div class="check_text">
                        <span>Festival Charge</span>
                      </div>
                      <div class="check_numver">
                        <span
                          ><span class="rupee">₹</span
                          >{{
                            parseFloat(
                              showCalc.festival_charge
                                ? showCalc.festival_charge
                                : 0
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}.00</span
                        >
                      </div>
                    </div>
                  </li>
                  <li class="check_rt_li">
                    <div class="check_rt_div">
                      <div class="check_text">
                        <span>Sub Total</span>
                      </div>
                      <div class="check_numver">
                        <span
                          ><span class="rupee">₹</span
                          >{{
                            parseFloat(
                              showCalc.sub_total ? showCalc.sub_total : 0
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}.00</span
                        >
                      </div>
                    </div>
                  </li>
                  <!-- <li class="check_rt_li">
                    <div class="check_rt_div">
                      <div class="check_text">
                        <span>GST ({{ showCalc.gst_tax_percent }}%)</span>
                      </div>
                      <div class="check_numver">
                        <span
                          ><span class="rupee">₹</span
                          >{{
                            parseFloat(showCalc.gst_tax ? showCalc.gst_tax : 0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}.00</span
                        >
                      </div>
                    </div>
                  </li> -->
                  <li class="check_rt_li">
                    <div class="check_rt_div">
                      <div class="check_text">
                        <span>(18%) GST INC.</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <div
                  class="check_rt_div total_price_col main_button_user_custom"
                >
                  <div class="check_rt_div">
                    <div class="check_text">
                      <span>Grand Total</span>
                    </div>
                    <div class="check_numver">
                      <p class="regu_price">
                        <span class="rupee">₹</span
                        >{{
                          parseFloat(
                            showCalc.final_total ? showCalc.final_total : 0
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}.00
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="main_reserves_col result_right">
                <button class="main_button_user" @click="bookPersonDetail">
                  <span>Reserve</span>
                </button>
              </div>
            </div>
          </li>
          <li class="bk_5" v-else-if="memberReserve == true">
            <div class="check_block" style="margin-bottom: 100px">
              <div class="check_left">
                <div class="check_inr_left">
                  <div class="check_in_out_box">
                    <div class="check_in_box">
                      <div class="check_in_title">
                        <span>Check-in</span>
                      </div>
                      <div class="check_in_text">
                        <span>{{ form.check_in_date }}</span>
                      </div>
                    </div>
                    <div class="check_in_box">
                      <div class="check_in_title">
                        <span>Check-out</span>
                      </div>
                      <div class="check_in_text">
                        <span>{{ form.check_out_date }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="check_in_out_box check_in_out_box_bottom"
                    v-if="!['EventBooking', 'OneDayPicnic'].includes(form.type)"
                  >
                    <div class="check_in_box">
                      <div class="check_in_title">
                        <span>You selected</span>
                      </div>
                      <div class="check_in_text check_in_select">
                        <div
                          v-if="form.type == 'OneDayPicnic'"
                          class="vila_title_info font_15"
                        >
                          <svg
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                              fill="#153936"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                              fill="#153936"
                            />
                          </svg>
                          <span>{{ form.person }}</span>
                        </div>
                        <div v-else-if="!form.type">
                          <div v-for="villa in selectedVilla" :key="villa.id">
                            <div
                              class="vila_title_info font_15"
                              v-if="villa.person"
                            >
                              <span>{{ villa.villa_name }}</span>
                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                  fill="#153936"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                  fill="#153936"
                                />
                              </svg>
                              <span>{{ villa.quantity }} x</span>
                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                                  fill="#153936"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                                  fill="#153936"
                                />
                              </svg>
                              <span>{{ villa.person }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="check_right">
                <div class="check_inr_right">
                  <ul class="check_rt_ul">
                    <li class="check_rt_li">
                      <div class="check_rt_div">
                        <div class="check_text">
                          <span v-if="form.type == 'OneDayPicnic'"
                            >Picnic Price</span
                          >
                          <span v-else-if="form.type == 'EventBooking'"
                            >Event Price</span
                          >
                          <span v-else>Villa Price</span>
                        </div>
                        <div class="check_numver">
                          <span
                            ><span class="rupee">₹</span
                            >{{
                              parseFloat(apiTotal ? apiTotal : 0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}.00</span
                          >
                        </div>
                      </div>
                    </li>
                    <li class="check_rt_li" v-if="form.extra_person_charge">
                      <div class="check_rt_div">
                        <div class="check_text">
                          <span>Extra Person</span>
                        </div>
                        <div class="check_numver">
                          <span
                            ><span class="rupee">₹</span
                            >{{
                              parseFloat(
                                form.extra_person_charge
                                  ? form.extra_person_charge
                                  : 0
                              )
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}.00</span
                          >
                        </div>
                      </div>
                    </li>
                    <li class="check_rt_li" v-if="form.festival_charge">
                      <div class="check_rt_div">
                        <div class="check_text">
                          <span>Festival Charge</span>
                        </div>
                        <div class="check_numver">
                          <span
                            ><span class="rupee">₹</span
                            >{{
                              parseFloat(
                                form.festival_charge ? form.festival_charge : 0
                              )
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}.00</span
                          >
                        </div>
                      </div>
                    </li>
                    <li class="check_rt_li">
                      <div class="check_rt_div">
                        <div class="check_text">
                          <span>Sub Total</span>
                        </div>
                        <div class="check_numver">
                          <span
                            ><span class="rupee">₹</span
                            >{{
                              parseFloat(form.subTotal ? form.subTotal : 0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}.00</span
                          >
                        </div>
                      </div>
                    </li>
                    <!-- <li class="check_rt_li">
                      <div class="check_rt_div">
                        <div class="check_text">
                          <span>GST ({{ form.gst_tax_percent }}%)</span>
                        </div>
                        <div class="check_numver">
                          <span
                            ><span class="rupee">₹</span
                            >{{
                              parseFloat(form.gst_tax ? form.gst_tax : 0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}.00</span
                          >
                        </div>
                      </div>
                    </li> -->
                    <li class="check_rt_li">
                      <div class="check_rt_div">
                        <div class="check_text">
                          <span>(18%) GST INC. </span>
                        </div>
                        <div class="check_numver">
                          <span
                            ><span class="rupee">₹</span
                            >{{
                              parseFloat(
                                showCalc.gst_tax ? showCalc.gst_tax : 0
                              ).toFixed(2)
                            }}</span
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="check_add_btn promocode_wrapper">
                    <template v-if="form.coupon_amount == 0">
                      <input
                        type="text"
                        name="coupon_code"
                        placeholder="Add Promo Code"
                        v-model="form.coupon_code"
                        class="pm_input"
                        v-show="isClickPromo"
                        style="width: 50%; margin-bottom: 5px"
                      />
                      <button
                        href=""
                        class="pm_butn mini_btn"
                        v-show="form.coupon_code == null"
                        @click="isClickPromo = !isClickPromo"
                      >
                        <span>Add A Promo Code</span>
                      </button>
                      <button
                        class="pm_butn mini_btn"
                        v-show="form.coupon_code != null"
                        @click="addPromoCode"
                      >
                        <span>Submit Code</span>
                      </button>
                    </template>
                  </div>
                  <div class="check_rt_div total_price_col">
                    <div class="check_rt_div">
                      <div class="check_text">
                        <span>Total</span>
                      </div>
                      <div class="check_numver">
                        <p class="sale_price" v-if="form.coupon_amount">
                          <span class="rupee">₹</span
                          >{{
                            parseFloat(
                              form.beforeDiscount ? form.beforeDiscount : 0
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}.00
                        </p>
                        <p class="regu_price">
                          <span class="rupee">₹</span
                          >{{
                            parseFloat(form.total ? form.total : 0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}.00
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="check_inr_right"
                  style="margin-top: 20px"
                  v-show="false"
                >
                  <div
                    class="check_rt_div total_price_col"
                    style="margin-top: 0px"
                  >
                    <div class="check_rt_div">
                      <div class="labelBox">
                        <label>
                          <input
                            type="radio"
                            name="payment_method"
                            :value="parseInt(2)"
                            v-model="form.payment_mode_id"
                          />
                          Online
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="main_reserves_row">
              <!-- <div class="main_reserves_col">
                <div class="main_reserves_col_inner">
                  <p>
                    <svg
                      style="margin-left: 10px"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                        fill="#153936"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                        fill="#153936"
                      />
                    </svg>
                    x {{ form.person }}
                  </p>
                  <span class="margin_top_10"
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(showCalc.total ? showCalc.total : 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                  <span class="main_class_innercol_span"
                    >+ <span class="rupee">₹</span
                    >{{
                      parseFloat(showCalc.gst_tax ? showCalc.gst_tax : 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00 Tax</span
                  >
                </div>
              </div> -->
              <div class="main_reserves_col">
                <button
                  class="main_button_user"
                  @click="BookNow"
                  v-show="!payurl"
                >
                  <span>Book Now</span>
                </button>
                <!-- <a class="main_button_user" :href="payurl" v-show="payurl">
                  <span>Book Now</span>
                </a> -->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </MasterLayout>
</template>
<script>
import Form from "vform";
import Group3 from "../../assets/images/User/Group3.png";
import banner02 from "../../assets/images/User/banner02.jpg";
import user_1 from "../../assets/images/User/user_1.png";
import user_icon from "../../assets/images/User/user-icon.png";
import call_icon from "../../assets/images/User/call_icon.png";
import trash from "../../assets/images/User/Trash_2.png";
import document from "../../assets/images/User/file-text white.png";
import edit from "../../assets/images/User/edit-icon.png";
import { ref } from "vue";
import MasterLayout from "./MasterLayout.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { isProxy, toRaw } from "vue";
import frame from "../../assets/images/User/Frame.png";

export default {
  name: "Dashbord",
  components: { MasterLayout },
  data() {
    return {
      main_name: "Dashboard",
      link: [{ link: "home", name: "Home" }],
      frame: frame,
      form: new Form({
        id: this.$route.query.id ? this.$route.query.id : null,
        check_in_date: this.$route.query.check_in_date
          ? this.$route.query.check_in_date
          : "",
        check_out_date: this.$route.query.check_out_date
          ? this.$route.query.check_out_date
          : "",
        total_child: this.$route.query.total_child
          ? this.$route.query.total_child
          : 0,
        person: this.$route.query.person
          ? this.$route.query.person
          : parseInt(0),
        qty: this.$route.query.qty ? this.$route.query.qty : 0,
        total: this.$route.query.total ? this.$route.query.total : 0,
        gst_tax: this.$route.query.gst_tax ? this.$route.query.gst_tax : 0,
        type: this.$route.query.type ? this.$route.query.type : null,
        extra_person_charge: this.$route.query.extra_person_charge
          ? this.$route.query.extra_person_charge
          : 0,
        festival_charge: this.$route.query.festival_charge
          ? this.$route.query.festival_charge
          : 0,
        gst_tax_percent: this.$route.query.gst_tax_percent
          ? this.$route.query.gst_tax_percent
          : 0,
        payment_mode_id: 2,
        rangeDate: ref(),
        villa_booking: [],
        persons: [
          {
            detail_id: this.detail_id,
            user_id: this.user_id,
            barcode_id: this.barcode_id,
            first_name: this.first_name,
            middle_name: this.middle_name,
            last_name: this.last_name,
            mobile: this.mobile,
            id_proof: this.id_proof,
            id_tmp_proof: this.id_tmp_proof,
            is_primary: true,
            user_type: localStorage.getItem("auth_user_type"),
          },
        ],
        coupon_code: null,
        coupon_amount: 0,
        subTotal: 0,
        beforeDiscount: 0,
      }),
      payurl: null,
      lastError: [],
      firstError: [],
      mobileError: [],
      villa_type_id: [],
      token: localStorage.getItem("auth_cus_token"),
      Group3: Group3,
      banner02: banner02,
      user_1: user_1,
      user_icon: user_icon,
      call_icon: call_icon,
      document: document,
      trash: trash,
      edit: edit,
      isClickPromo: false,
      promoError: "none",
      loader: false,
      memberReserve: false,
      apiTotal: 0,
      selectedVilla: [],
      dashboardBooking: [],
      authUser: {},
    };
  },
  beforeCreate() {
    this.payment_mode_id = 2;
  },
  created() {
    this.form.id = localStorage.getItem("id") ? localStorage.getItem("id") : "";
    if (localStorage.getItem("id")) {
      this.form.check_in_date = localStorage.getItem("check_in_date")
        ? localStorage.getItem("check_in_date")
        : "";
      this.form.check_out_date = localStorage.getItem("check_out_date")
        ? localStorage.getItem("check_out_date")
        : "";
      this.form.total_child = localStorage.getItem("total_child")
        ? localStorage.getItem("total_child")
        : 0;
      this.form.person = localStorage.getItem("total_adult")
        ? localStorage.getItem("total_adult")
        : parseInt(0);
      // console.log(localStorage.getItem('total_adult'));
      this.form.qty = localStorage.getItem("qty")
        ? localStorage.getItem("qty")
        : 0;
      this.form.total = localStorage.getItem("total")
        ? localStorage.getItem("total")
        : 0;
      this.form.gst_tax = localStorage.getItem("gst_tax")
        ? localStorage.getItem("gst_tax")
        : 0;
      this.form.type = localStorage.getItem("type")
        ? localStorage.getItem("type")
        : "";
      this.form.extra_person_charge = localStorage.getItem(
        "extra_person_charge"
      )
        ? localStorage.getItem("extra_person_charge")
        : 0;
      this.form.festival_charge = localStorage.getItem("festival_charge")
        ? localStorage.getItem("festival_charge")
        : 0;
      this.form.gst_tax_percent = localStorage.getItem("gst_tax_percent")
        ? localStorage.getItem("gst_tax_percent")
        : 0;
      this.form.beforeDiscount = 0;
    }
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!this.token) {
      this.$router.push({ name: "home" });
      window.location.href = "/";
    }
    this.getBookingPersonDetail();
    this.getBookingData();
    if (this.person > 0) {
      for (var i = 1; i < this.person; i++) {
        this.form.persons.push({
          detail_id: this.form.detail_id,
          user_id: this.form.user_id,
          barcode_id: this.form.barcode_id,
          first_name: this.form.first_name,
          middle_name: this.form.middle_name,
          last_name: this.form.last_name,
          mobile: this.form.mobile,
          id_proof: this.form.id_proof,
          id_tmp_proof: this.form.id_tmp_proof,
          is_primary: false,
        });
        this.form.persons.detail_id = null;
        this.form.persons.user_id = null;
        this.form.persons.barcode_id = null;
        this.form.persons.first_name = "";
        this.form.persons.middle_name = "";
        this.form.persons.last_name = "";
        this.form.persons.mobile = "";
        this.form.persons.id_proof = "";
        this.form.persons.id_tmp_proof = "";
        this.form.persons.is_primary = "";
      }
    }
    this.getUserProfile();
  },
  computed: {
    ...mapGetters(["allPlatinums"]),
    showCalc: function () {
      const price = parseFloat(
        this.form.total -
          this.form.festival_charge -
          this.form.extra_person_charge -
          this.form.gst_tax
      );

      const festival_charge = this.form.festival_charge;
      const extra_person_charge = this.form.extra_person_charge;
      const sub_total =
        parseFloat(price) +
        parseFloat(festival_charge) +
        parseFloat(extra_person_charge);

      return {
        total: price,
        festival_charge: festival_charge,
        extra_person_charge: extra_person_charge,
        sub_total: sub_total,
        gst_tax: sub_total - sub_total * (100 / (100 + 18)),
        gst_tax_percent: this.form.gst_tax_percent,
        final_total: this.form.total,
      };
    },
  },
  methods: {
    ...mapActions(["addPlatinum"]),
    addToContent() {
      this.form.persons.push({
        detail_id: this.form.detail_id,
        user_id: this.form.user_id,
        barcode_id: this.form.barcode_id,
        first_name: this.form.first_name,
        middle_name: this.form.middle_name,
        last_name: this.form.last_name,
        mobile: this.form.mobile,
        id_proof: this.form.id_proof,
        id_tmp_proof: this.form.id_tmp_proof,
        is_primary: false,
        user_type: localStorage.getItem("auth_user_type"),
      });
      this.form.persons.detail_id = null;
      this.form.persons.user_id = null;
      this.form.persons.barcode_id = null;
      this.form.persons.first_name = "";
      this.form.persons.middle_name = "";
      this.form.persons.last_name = "";
      this.form.persons.mobile = "";
      this.form.persons.id_proof = "";
      this.form.persons.id_tmp_proof = "";
      this.form.persons.is_primary = "";

      this.person = this.person + 1;
      // this.replaseUrl(this.person);
    },
    uploadProofImage(index, e) {
      let imgProof = e.target.files[0];
      if (imgProof.size / 1024 >= 400) {
        e.target.value = null;
        this.$toast.error("File too Big, please select a file less than 400Kb");
        return false;
      }
      this.createBase64Image(imgProof, index);
    },
    createBase64Image(fileObject, index) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageEncode = e.target.result.split("base64,");
        this.form.persons[index].id_tmp_proof = e.target.result;
        this.form.persons[index].id_proof = newImageEncode[1];
      };
      reader.readAsDataURL(fileObject);
    },
    removeContent(idx, detailId = null) {
      console.log(detailId);
      this.form.persons.splice(idx, 1);
      this.person = this.person - 1;
      // this.replaseUrl(this.person);
    },
    // replaseUrl(person) {
    //     this.$router.replace({...this.$router.currentRoute, query: { id: this.form.id, qty: this.form.qty, check_in_date: this.form.check_in_date, check_out_date: this.form.check_out_date, total_child: this.form.total_child, person: person, total: this.total, gst_tax: this.gst_tax },});
    // },
    async getBookingData() {
      this.loader = true;
      await axios
        .get(process.env.VUE_APP_API_CUS_URL + "/dashboard", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          // console.log("response: ", response);
          this.dashboardBooking = response.data.data;
          for (const card1 of this.dashboardBooking) {
            console.log("card1: ", card1);
          }
          // console.log("this.dashboardBooking: ", this.dashboardBooking);
          localStorage.removeItem("id");
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    getBookingPersonDetail() {
      let rawData = this.allPlatinums;
      // console.log("rawData dashboard: ", rawData);
      if (isProxy(this.allPlatinums)) {
        rawData = toRaw(this.allPlatinums);
      }
      if (rawData[0]) {
        this.form.id = rawData[0].id;
        this.form.qty = rawData[0].qty;
        this.form.check_in_date = rawData[0].check_in_date;
        this.form.check_out_date = rawData[0].check_out_date;
        this.form.total_child = rawData[0].total_child;
        this.form.person = rawData[0].total_adult;
        this.form.total = rawData[0].booking.total;
        this.form.gst_tax = rawData[0].booking.gst_tax;
        this.form.type = rawData[0].booking.type;
        this.form.extra_person_charge = rawData[0].booking.extra_person_charge;
        this.form.festival_charge = rawData[0].booking.festival_charge;
        this.form.gst_tax_percent = rawData[0].booking.gst_tax_percent;
        this.form.payment_mode_id = 2;
        this.form.villa_booking = rawData[0].villa_booking;
      }
    },
    async addPromoCode() {
      if (this.form.coupon_code.length === 0) {
        this.$toast.error("Please add promo code");
      } else {
        await axios
          .post(
            process.env.VUE_APP_API_CUS_URL + "/coupon-code",
            {
              check_in_date: this.form.check_in_date,
              check_out_date: this.form.check_out_date,
              coupon_code: this.form.coupon_code,
              total: this.apiTotal,
              extra_person_charge: this.form.extra_person_charge,
              festival_charge: this.form.festival_charge,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data?.message);
              this.form.coupon_amount = response.data.data?.coupon_amount;
              this.form.total = response.data.data?.total;
              this.form.extra_person_charge =
                response.data.data?.extra_person_charge;
              this.form.festival_charge = response.data.data?.festival_charge;
              this.priceCalculation();
            } else {
              this.$toast.error(response.data?.message);
            }
          });
      }
    },
    async bookPersonDetail() {
      // console.log('this.form: ', this.form);
      for (var i = 0; i < this.form.persons.length; i++) {
        if (
          this.form.persons[i].first_name == undefined ||
          this.form.persons[i].first_name == null
        ) {
          this.firstError[i] = true;
          return;
        }
        if (
          this.form.persons[i].last_name == undefined ||
          this.form.persons[i].last_name == null
        ) {
          this.lastError[i] = true;
          return;
        }
        if (
          this.form.persons[i].mobile == undefined ||
          this.form.persons[i].mobile == null
        ) {
          this.mobileError[i] = true;
          return;
        }
      }
      // console.log('villa_booking length',this.form.villa_booking);
      if (this.form.person == this.form.persons.length) {
        // if(this.form.villa_booking.length > 0) {
        if (this.form.villa_booking ? this.form.villa_booking.length : 0) {
          var vBookingsummary = this.form.villa_booking;
        } else {
          vBookingsummary = [
            {
              villa_type_id: this.form.id,
              quantity: this.form.qty,
              person: this.form.person,
            },
          ];
        }

        await axios
          .post(
            process.env.VUE_APP_API_CUS_URL + "/price-summary",
            {
              check_in_date: this.form.check_in_date,
              check_out_date: this.form.check_out_date,
              total:
                this.form.total - this.form.gst_tax - this.form.festival_charge,
              extra_person_charge: this.form.extra_person_charge,
              festival_charge: this.form.festival_charge,
              gst_tax: this.form.gst_tax_percent,
              type: this.form.type,
              person: this.form.person,
              villa_booking: vBookingsummary,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
              },
            }
          )
          .then((response) => {
            // console.log("response: ", response);
            if (response.data.status) {
              //  this.$toast.success(response.data?.message);
              this.memberReserve = true;
              const result = response.data.data;
              this.form.check_in_date = result?.check_in_date;
              this.form.check_out_date = result?.check_out_date;
              this.form.extra_person_charge = result?.extra_person_charge;
              this.form.festival_charge = result?.festival_charge;
              this.form.person = result?.person;
              this.form.total = result?.total;
              this.apiTotal = result?.total;
              this.form.gst_tax_percent = result?.gst_tax;

              let rawData = response.data.data?.villa_booking;
              if (isProxy(response.data.data?.villa_booking)) {
                rawData = toRaw(response.data.data?.villa_booking);
              }
              this.selectedVilla = rawData;
              this.priceCalculation();
              this.addPlatinum(this.form);
            } else {
              this.$toast.error(response.data?.message);
            }
          });
      } else {
        this.$toast.error("Your member detail not complete");
      }
    },
    async BookNow() {
      if (this.form.villa_booking ? this.form.villa_booking.length : 0) {
        var vBookingvilla = this.form.villa_booking;
      } else {
        vBookingvilla = [
          {
            villa_type_id: this.form.id,
            quantity: this.form.qty,
            person: this.form.person,
          },
        ];
      }

      await axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/villa-book",
          {
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
            total_child: this.form.total_child,
            coupon_code: this.form.coupon_code,
            coupon_amount: this.form.coupon_amount,
            payment_mode_id: this.form.payment_mode_id,
            total: this.apiTotal,
            extra_person_charge: this.form.extra_person_charge,
            festival_charge: this.form.festival_charge,
            gst_tax: this.form.gst_tax_percent,
            gst_amount: this.form.gst_tax,
            person: this.form.person,
            sub_total: this.form.subTotal,
            final_total: this.form.total,
            villa_booking: vBookingvilla,
            persons: this.form.persons,
            type: this.form.type,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.payurl = null;

            if (this.form.payment_mode_id == 1) {
              this.$toast.success(response.data?.message);
              localStorage.removeItem("id");
              this.memberReserve = false;
              this.form.id = null;
              this.getBookingData();
            } else {
              this.getPayLink(response.data.data.booking_id);
            }
          } else {
            this.$toast.error(response.data?.message);
          }
        });
    },
    async getPayLink(booking_id) {
      this.payurl = null;

      const formdata = new FormData();
      formdata.append("amount", this.form.total);
      formdata.append("user_id", this.authUser.id);
      formdata.append("device", "web");
      formdata.append("mobileNumber", this.authUser.mobile);
      formdata.append("redirectMode", "REDIRECT");
      formdata.append("booking_id", booking_id);
      // formdata.append('redirectUrl', process.env.VUE_APP_URL + "/dashboard?booking_id=" + booking_id)
      // formdata.append('callbackUrl', process.env.VUE_APP_API_CUS_URL + "/order/" + booking_id + "/" + this.authUser.id)
      // formdata.append('callbackUrl', "https://crm-server.riocolina.com/api/customer/order/" + booking_id + "/" + this.authUser.id)

      await axios
        .post(process.env.VUE_APP_API_CUS_URL + "/pay-online", formdata, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        // await axios.post("https://crm-server.riocolina.com/api/customer/pay-online", formdata)
        .then((response) => {
          if (response.data.links) {
            this.payurl = response.data.links.pay_link_web;
            window.location.href = response.data.links.pay_link_web;
          }
        });
    },
    async BookVilla() {
      // if(this.form.villa_booking.length > 0) {
      if (this.form.villa_booking ? this.form.villa_booking.length : 0) {
        var vBookingvilla = this.form.villa_booking;
      } else {
        vBookingvilla = [
          {
            villa_type_id: this.form.id,
            quantity: this.form.qty,
            person: this.form.person,
          },
        ];
      }

      await axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/villa-book",
          {
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
            total_child: this.form.total_child,
            coupon_code: this.form.coupon_code,
            coupon_amount: this.form.coupon_amount,
            payment_mode_id: this.form.payment_mode_id,
            total: this.apiTotal,
            extra_person_charge: this.form.extra_person_charge,
            festival_charge: this.form.festival_charge,
            gst_tax: this.form.gst_tax_percent,
            gst_amount: this.form.gst_tax,
            person: this.form.person,
            sub_total: this.form.subTotal,
            final_total: this.form.total,
            villa_booking: vBookingvilla,
            persons: this.form.persons,
            type: this.form.type,
            id: this.form.id ? parseInt(this.form.id) : null,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.$toast.success(response.data?.message);
            localStorage.removeItem("id");
            this.memberReserve = false;
            this.form.id = null;
            this.getBookingData();
          } else {
            this.$toast.error(response.data?.message);
          }
        });
    },
    priceCalculation() {
      if (this.form.coupon_amount) {
        this.form.gst_tax =
          ((parseFloat(this.form.total) +
            parseFloat(this.form.extra_person_charge) +
            parseFloat(this.form.festival_charge) -
            parseFloat(this.form.coupon_amount)) *
            parseFloat(this.form.gst_tax_percent)) /
          100;
        this.form.subTotal =
          parseFloat(this.form.total) +
          parseFloat(this.form.extra_person_charge) +
          parseFloat(this.form.festival_charge) -
          parseFloat(this.form.coupon_amount);
        this.form.total =
          parseFloat(this.form.subTotal) + parseFloat(this.form.gst_tax);
      } else {
        this.form.gst_tax =
          ((parseFloat(this.form.total) +
            parseFloat(this.form.extra_person_charge) +
            parseFloat(this.form.festival_charge)) *
            parseFloat(this.form.gst_tax_percent)) /
          100;
        this.form.subTotal =
          parseFloat(this.form.total) +
          parseFloat(this.form.extra_person_charge) +
          parseFloat(this.form.festival_charge);
        this.form.total =
          parseFloat(this.form.subTotal) + parseFloat(this.form.gst_tax);
      }
      this.form.beforeDiscount = parseFloat(
        this.form.total + this.form.coupon_amount
      ).toFixed(2);
    },
    async getUserProfile() {
      this.loader = true;
      await axios
        .get(process.env.VUE_APP_API_CUS_URL + "/user-profile", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.authUser = response.data.data;
          if (
            response.data.data.bookings_panding_by_owners.length > 0 &&
            this.$router.options.history.state.back == "/"
          ) {
            this.$router.push({ name: "booking-pending" });
          }
          // console.log("auth: ", this.authUser);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    removeLocalstorage() {
      localStorage.removeItem("id");
      localStorage.removeItem("check_in_date");
      localStorage.removeItem("check_out_date");
      localStorage.removeItem("total_child");
      localStorage.removeItem("total_adult");
      localStorage.removeItem("qty");
      localStorage.removeItem("type");

      localStorage.removeItem("total");
      localStorage.removeItem("festival_charge");
      localStorage.removeItem("extra_person_charge");
      localStorage.removeItem("gst_tax");
      localStorage.removeItem("gst_tax_percent");
    },
  },
};
</script>

<style scoped>
.other_member_detail {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: none;
}

.result_left {
  width: 30%;
}

.result_right {
  display: flex;
  width: 70%;
  float: right;
  justify-content: end;
}

.tab_inner_content {
  display: flex;
  flex-wrap: wrap;
}

.tab_inner_content_main {
  height: fit-content;
  margin: 10px;
  padding: 15px;
  display: inline-flex;
  width: 48%;
}

.tab_inner_content_image {
  width: 30%;
  height: 100px;
}

.tab_inner_content_image img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
}

.d_flex {
  display: flex;
}

.promocode_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab_inner_content_date {
  padding: 0 10px;
  font-size: 20px;
  width: 100%;
}

.tab_inner_content_add_user {
  display: flex;
  align-items: center;
  border-radius: 100px;
  margin-top: 0;
  padding: 0 10px 0 0px;
  background: transparent;
  font-size: 15px;
}

.aview_bar {
  padding: 8px;
}

.lightbox {
  top: 50% !important;
}

.v_bk_cat {
  text-align: center;
}

.labelBox {
  width: 50%;
}

.labelBox label {
  cursor: pointer;
}

a.main_button_user {
  background: #42aea3;
  border: none;
  color: #fff !important;
  border-radius: 100px;
  padding: 20px;
}

.main_reserves_row {
  align-items: center;
}

.tab_inner_content_main {
  display: flex;
  flex-direction: column;
}

.tab_inner_content_main .footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cccccc5e;
  padding-top: 15px;
  align-items: center;
}

.tab_inner_content_main .footer .amount {
  width: 120px;
}

a.text-white {
  color: #fff !important;
}

.total_price_col .check_numver {
  width: 145px;
}

.sale_price {
  font-size: 14px;
  margin-right: 5px;
}

.aview_bar.success {
  background-color: #5cb85c;
  border-color: #4cae4c;
  color: #fff;
  cursor: not-allowed;
}

.aview_bar.warning {
  background-color: #f0ad4e;
  color: #fff;
}

.aview_bar.danger {
  background-color: red;
  color: #fff;
}

.mr-1 {
  margin-right: 10px;
}

#dm .aview_bar a {
  color: #fff !important;
}

#dm a.main_button_user {
  color: #fff !important;
}

#dm .memeber_list .type_title {
  margin-bottom: 10px;
}

#dm .pm_detail_col {
  justify-content: normal;
}

#dm .pm_detail_12:first-child {
  width: 50%;
}

#dm .pm_detail_12:nth-child(3) {
  width: 25%;
}

#dm .trash {
  padding: 12px 20px;
  background-color: #d13535;
}

#dm .trash.disbaled {
  opacity: 0.5;
  cursor: not-allowed;
}

#dm .pm_deatil_in {
  justify-content: space-between;
  width: 100%;
}

#dm .add_member {
  justify-content: end;
}

#dm .other_member_detail {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

#dm .main_button_user_square_plus {
  padding: 20px 15px;
  margin-right: 27px;
}

#dm .plus {
  margin-right: 0px;
}

#dm .error {
  margin-top: 5px;
}

#dm .main_reserves_row {
  position: relative !important;
}

#dm .main_reserves_row {
  position: relative !important;
  flex-wrap: wrap;
}

#dm .main_reserves_col.result_left {
  display: flex;
  width: 100%;
  text-align: end;
}

#dm .main_reserves_col_inner {
  margin: auto;
  margin-right: 0px;
}

#dm .main_reserves_col.result_right {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

#dm .main_reserves_col {
  width: 100%;
  display: flex;
  text-align: end;
}

#dm button.main_button_user {
  margin: auto;
  margin-right: 0;
}

#dm span.margin_top_10 {
  margin-top: 10px;
  display: block;
}

#dm .main_reserves_row {
  position: relative !important;
  flex-wrap: wrap;
  border-radius: 10px;
  box-shadow: 0px 0px 14px #dceae8;
}

#dm .new_bottom .main_reserves_row {
  justify-content: end;
}

#dm .new_bottom .check_inr_right {
  width: 50%;
  padding-top: 5px;
}

#dm .new_bottom li.check_rt_li .check_rt_div {
  padding: 10px 0;
}

#dm .new_bottom .total_price_col {
  margin-top: 0px;
  padding: 10px 0px;
}

#dm .new_bottom {
  justify-content: end;
}
#dm .new_bottom .main_button_user_custom {
  background: #42aea3;
  border: none;
  color: #fff;
  border-radius: 0 0 9px 9px;
  padding: 10px 20px;
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: -21px;
}

@media all and (min-width: 200px) and (max-width: 991px) {
  #dm .w3-container {
    padding: 25px 20px;
  }

  .row {
    margin-right: unset;
    margin-left: unset;
  }

  #dm .pm_detail_12 {
    width: 100% !important;
  }

  #dm .pm_detail_col .pm_detail_12:last-child {
    padding-top: 20px;
  }

  #dm .pm_detail_form .pm_detail_col:last-child .pm_detail_rt {
    display: flex !important;
    justify-content: center !important;
  }

  #dm .main_button_user_square_plus {
    margin-right: 0px;
  }

  #dm .new_bottom .check_inr_right {
    width: 100%;
  }

  #dm .main_reserves_col {
    justify-content: center;
  }

  #dm button.main_button_user {
    margin: unset;
    margin-right: 0px;
    margin-top: 17px;
  }
}
</style>
